<script>
import GoodsService from '@/app/Services/GoodsService'

export default {
  data() {
    return {
      goodsList: [],
    }
  },

  methods: {
    async getGoods() {
      const response = await GoodsService.getGoods()

      if (response.status) {
        this.goodsList = response.data

        this.$store.dispatch('goods/setGoodsData', response.data)
      }
    },
  },
}
</script>
