import api from '@/app/api'

export default {
  async getGoods() {
    const response = await api.get('/item')
    return response
  },

  async createGoods(goodsData) {
    const response = await api.post('/item', goodsData)
    return response
  },

  async getGoodsById(goodsId) {
    const response = await api.get(`/item/${goodsId}`)
    return response
  },

  async deleteGoods(goodsId) {
    const response = await api.delete(`/item/${goodsId}`)
    return response
  },

  async editGoods(goodsId, goodsData) {
    const response = await api.post(`/item/${goodsId}?_method=PUT`, goodsData)
    return response
  },

  async getExtra() {
    const response = await api.get('/extra')
    return response
  },

  async createExtra(extraData) {
    const response = await api.post('/extra', extraData)
    return response
  },

  async getExtraById(extraId) {
    const response = await api.get(`/extra/${extraId}`)
    return response
  },

  async deleteExtra(extraId) {
    const response = await api.delete(`/extra/${extraId}`)
    return response
  },

  async editExtra(extraId, extraData) {
    const response = await api.post(`/extra/${extraId}?_method=PUT`, extraData)
    return response
  },
}
