import api from '@/app/api'

export default {
  async getClients() {
    const response = await api.get('/client')
    return response
  },

  async createClient(clientData) {
    const response = await api.post('/client', clientData)
    return response
  },

  async getClientById(clientId) {
    const response = await api.get(`/client/${clientId}`)
    return response
  },

  async editClient(clientId, clientData) {
    const response = await api.put(`/client/${clientId}`, clientData)
    return response
  },

  async deleteClient(clientId) {
    const response = await api.delete(`/client/${clientId}`)
    return response
  },
}
